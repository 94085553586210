@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.App {
    text-align: center;
}