.footer{
    padding: 4rem 6rem;
    background: #0d1180;
    color: #fff;
}

img{
    width: 50%;
    height: 50%;
   
}

h1{
    margin-bottom: 1rem;
    font-size: 20px;
}

.top{
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap
}

.top i{
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i:hover{
    color: #01959a;
}

@media screen and (max-width: 850px){
    .footer{
        padding: 4rem 2 rem;
    }

    .footer i{
        margin: 1 rem 1 rem 0 0;
    }
    
    
}