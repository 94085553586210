.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1{
    padding-top: 4rem !important;
}


img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%
}

.hero-text h1{
    font-size: 6rem;
    font-weight: 800;
    background: #000000;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p{
    font-size: 4rem;
    font-weight: 800;
    color: #000000;
    padding: 0.5rem 0 3rem 0;
}

@media screen and (max-width: 555px){
    .hero-text h1{
        padding: 10px 20px;
    }

    .hero-text p{
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    
    
}